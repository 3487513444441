import React, { useEffect } from 'react';

function Disclaimer() {

  useEffect(() => {
    if (localStorage.getItem("cookies-policy-approved") !== 'true') {
      window.$("#disclaimerModal").modal('toggle');
    }
  }, []);

  return (
    <div className="modal fade" id="disclaimerModal" tabIndex="-1" aria-labelledby="disclaimerModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-fullscreen">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="disclaimerModalLabel">Disclaimer</h1>
            {/*<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>*/}
          </div>
          <div className="modal-body">
            Ce site utilise des cookies pour améliorer votre expérience. Nous supposons que vous êtes d'accord, mais vous êtes libre de les refuser.
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={() => {
              console.log("Accept");
              localStorage.setItem("cookies-policy-approved", "true");
              return;
            }}>Accept</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Disclaimer;

import React, { useState } from 'react';
import './App.css';
import Footer from './Footer';

function Others() {
  const [filter, setFilter] = useState('');

  let other_gis_data_sources = [
    {
      title: 'SIE HAITI',
      subtitle: '',
      text: 'SIE Haiti is an information system owned by the Ministry of the Environment in Haiti. It supports the collection, management, and dissemination of environmental data to enhance decision- making and promote sustainable development',
      img: 'siehaiti.PNG',
      imgstyle: {},
      link: 'https://www.sie-haiti.org/#/'
    },
    {
      title: 'Geoportal DGPC (MangoMap)',
      subtitle: '',
      text: 'The Civil Protection in Haiti geoportal is an online platform designed to manage and share geospatial data related to disaster risk and emergency management.',
      img: 'GeoportalDGPC.PNG',
      imgstyle: {},
      link: 'https://mangomap.com/direction-protection-civile-haiti/maps/118678/webmap-protection-civile- haiti?preview=true#zoom=8&amp;lat=19.051734&amp;lng=- 71.779175&amp;layergroups=Direction%20Protection%20Civile%20Haiti%3Ab408c2e2-ac3b-11eb-a110- 06765ea3034e,Direction%20Protection%20Civile%20Haiti%3A7ddfe1d4-ac48-11eb-b4a7- 06765ea3034e,Direction%20Protection%20Civile%20Haiti%3Abb629860-ac3b-11eb-b04e- 06765ea3034e,Direction%20Protection%20Civile%20Haiti%3A43125046-ac2f-11eb-b4a7- 06765ea3034e&amp;bck=grayscale&amp;permalink=true'
    },
    {
      title: 'RO Haiti',
      subtitle: '',
      text: 'RO Haiti was developed to leverage satellite data for disaster recovery, especially after Hurricane Matthew in 2016. The platform enhances the integration of geospatial information into recovery efforts, supporting better monitoring and management of disaster impacts.',
      img: 'rohaiti.PNG',
      imgstyle: {},
      link: 'https://www.recovery-observatory.org/drupal/en'
    },
    {
      title: 'HDX',
      subtitle: '',
      text: 'HDX (Humanitarian Data Exchange) is an open platform managed by the United Nations Office for the Coordination of Humanitarian Affairs (OCHA). It facilitates the sharing and accessibility of humanitarian data, enabling better decision-making and coordination during crises.',
      img: 'hdx.png',
      imgstyle: {},
      link: 'https://data.humdata.org/search?q=haiti'
    },
  ];

  return (
    <>
      <nav className="navbar navbar-expand-lg bg-body-tertiary">
        <div className="container-fluid">
          <img src="cnigs-logo.png" className="card-img-top" alt="..." style={{ height: '70px', width: '70px' }} />
          <a className="navbar-brand" href="#">SDI HAITI</a>
          <span />
        </div>
      </nav>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="/">SDI HAITI</a></li>
          <li class="breadcrumb-item active" aria-current="page">Other GIS data sources</li>
        </ol>
      </nav>

      <div className="container"> {/* text-center */}

        <div className="row">
          <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam porta vitae metus vel sollicitudin. Morbi malesuada ....</span>
        </div>

        <div className="row">
          {
            other_gis_data_sources
              .filter(
                item => item?.title?.toUpperCase()?.includes(filter?.toUpperCase())
                  || item?.subtitle?.toUpperCase()?.includes(filter?.toUpperCase())
                  || item?.text?.toUpperCase()?.includes(filter?.toUpperCase())
              )
              .map((item, index) =>
                <div key={index} className="col-lg-3 col-md-6 col-sm-12">
                  <div className="card">
                    <div className="card-header">
                      <h5 className="card-title">{item.title}</h5>
                      <h6 className="card-subtitle mb-2 text-body-secondary">{item.subtitle}</h6>
                      <p className="card-text">{item.text}</p>
                    </div>
                    <div className="card-body d-flex align-items-center justify-content-center">
                      <img src={item.img} className="card-img-top" alt="..." style={item.imgstyle} />
                    </div>
                    <div class="card-footer d-flex align-items-center justify-content-center">
                      <a href={item.link} className="btn btn-primary" target="_blank">se connecter</a>
                    </div>
                  </div>
                </div>)
          }
        </div>
      </div>

      <Footer/>
    </>
  );
}

export default Others;

import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import Footer from './Footer';

function App() {
  const [filter, setFilter] = useState('');

  let mycards = [
    {
      title: 'Geoportal (Mapstore)',
      subtitle: 'Geoportal (Mapstore)',
      text: 'MapStore is an open-source web application for creating, managing, and sharing maps and spatial data. It will act as the geoportal for CNIGS, enhancing the visualization and accessibility of Haiti’s spatial layers. The geoportal is a dynamic, user-friendly mapping tool designed for non-technical GIS users, making it easier for a broader audience to create and interact with spatial data',
      img: 'MapStore2.png',
      link: '/mapstore/?locale=fr'
    },
    {
      title: 'Open-data (Geonode)',
      subtitle: 'Open-data (Geonode)',
      text: 'GeoNode is an open data spatial platform designed for managing, sharing, and visualizing geospatial data, primarily for technical GIS users. CNIGS will use GeoNode to disseminate spatial data in Haiti, enhancing accessibility and supporting informed decision-making.',
      img: 'geonode_logo.png',
      imgstyle: { height: '80px', width: '273px' },
      alert: "Notre plateforme d'open data est en cours d'amélioration et sera bientôt de nouveau en ligne. Nous nous excusons pour ce désagrément et vous remercions pour votre compréhension."
    }
  ];

  let mylinks = [
    {
      title: 'Other GIS data sources',
      subtitle: 'Other GIS data sources',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam porta vitae metus vel sollicitudin. Morbi malesuada ....',
      to: '/others'
    },
    {
      title: 'Partner Institutions',
      subtitle: 'Partner Institutions',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam porta vitae metus vel sollicitudin. Morbi malesuada ....',
      to: '/partnerinstitutions'
    }
  ];


  /*
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <form className="d-flex" role="search">
            <input className="form-control me-2" type="search" placeholder="Recherche" aria-label="Search"
              value={filter}
              onChange={(event) => setFilter(event.target.value)}
            />
          </form>
        </div>
  */


  return (
    <>
      <ToastContainer />
      <nav className="navbar navbar-expand-lg bg-body-tertiary">
        <div className="container-fluid">
          <img src="cnigs-logo.png" className="card-img-top" alt="..." style={{ height: '70px', width: '70px' }} />
          <a className="navbar-brand" href="#">SDI HAITI</a>
          <span />
        </div>
      </nav>

      <div className="container"> {/* text-center */}

        <div className="row">
          <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam porta vitae metus vel sollicitudin. Morbi malesuada ....</span>
        </div>

        <div className="row">
          {
            mycards
              .filter(
                item => item?.title?.toUpperCase()?.includes(filter?.toUpperCase())
                  || item?.subtitle?.toUpperCase()?.includes(filter?.toUpperCase())
                  || item?.text?.toUpperCase()?.includes(filter?.toUpperCase())
              )
              .map((item, index) =>
                <div key={index} className="col-md-6 col-sm-12">
                  <div className="card">
                    <div className="card-header">
                      <h5 className="card-title">{item.title}</h5>
                      <h6 className="card-subtitle mb-2 text-body-secondary">{item.subtitle}</h6>
                      <p className="card-text">{item.text}</p>
                    </div>
                    <div className="card-body d-flex align-items-center justify-content-center">
                      <img src={item.img} className="card-img-top" alt="..." style={item.imgstyle} />
                    </div>
                    <div class="card-footer d-flex align-items-center justify-content-center">
                      {item?.link && <a href={item.link} className="btn btn-primary" target="_blank">se connecter</a>}
                      {item?.alert &&
                        <button type="button" className="btn btn-primary" onClick={() => {
                          toast(item?.alert);
                        }}>se connecter</button>
                      }
                    </div>
                  </div>
                </div>)
          }

          {
            mylinks
              .filter(
                item => item?.title?.toUpperCase()?.includes(filter?.toUpperCase())
                  || item?.subtitle?.toUpperCase()?.includes(filter?.toUpperCase())
                  || item?.text?.toUpperCase()?.includes(filter?.toUpperCase())
              )
              .map((item, index) =>
                <div key={index} className="col-md-6 col-sm-12">
                  <div className="card" style={{ minHeight: '250px' }}>
                    <div className="card-body">
                      <h5 className="card-title">{item.title}</h5>
                      <h6 className="card-subtitle mb-2 text-body-secondary">{item.subtitle}</h6>
                      <p className="card-text">{item.text}</p>
                    </div>
                    <div class="card-footer d-flex align-items-center justify-content-center">
                      <Link className="btn btn-primary" to={item.to}>se connecter</Link>
                    </div>
                  </div>
                </div>)
          }
        </div>

      </div>

      <Footer/>
    </>
  );
}

export default App;

import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';

function Footer() {
    return (
        <>
            <nav className="navbar navbar-expand-lg bg-body-tertiary">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-6 col-sm-12">
                            <span>Address:</span><br />
                            <span>Centre National de l’Information Géo-Sspatiale (CNIGS) Rue Faustin 1er, 4, Turgeau Port-au-Prince</span>
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <span>Telephone:</span><br />
                            <span>(509) 22 44 99 80, (509) 22 44 99 81/(+509) 22 44 99 82/ (+509) 510 76 20</span><br />
                            <span>Fax:</span><br />
                            <span>Email: cnigs.haiti@yahoo.com</span>
                        </div>
                    </div>
                </div>
            </nav>
        </>
    );
}

export default Footer;